import React from 'react';
import { graphql } from 'gatsby';
import { Global } from '@emotion/core';
import global from '../styles/global';
import { ContentfulStaticPage } from '../graphql-types';

interface Props {
  data: {
    contentfulStaticPage: ContentfulStaticPage;
  };
}

const WebviewPage: React.FC<Props> = ({ data }) => (
  <>
    <Global styles={global} />
    <div
      css={{ margin: 8 }}
      dangerouslySetInnerHTML={{
        __html: data.contentfulStaticPage.content.childMarkdownRemark.html,
      }}
    />
  </>
);

export default WebviewPage;

export const webviewPrivacyAppQuery = graphql`
  query webviewPrivacyAppQuery($id: String!) {
    contentfulStaticPage(id: { eq: $id }) {
      slug
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
